<template>
  <div class="details">
    <Navigation />
    <div class="main">
      <h3>{{title}}</h3>
      <p>发布时间：{{createtime}}</p>
      <div class="cont" v-html="content"></div>
    </div>
    <consulting />
    <foot />
  </div>
</template>



<script>
import Navigation from "@/components/header.vue";
import consulting from "@/components/consulting.vue";
import footer from "@/components/footer.vue";
export default {
  components: {
    Navigation,
    consulting,
    foot: footer,
  },
  data() {
    return {
      id: "",
      title: "",
      createtime: "",
      content: "",
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    console.log(this.id);
    this.getarticleShow();
  },
  methods: {
    async getarticleShow() {
      const res = await this.axios.get(
        `api/index/articleShow?article_id=${this.id}`
      );
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.title = data.title;
        this.createtime = data.createtime;
        this.content = this.setimg(data.content);
      }
    },
    setimg(a) {
      // var a = '<P><img src="http://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="https://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="/200701/31/jisuanji986_117025184198149.jpg"><img src="/jisuanji986_117025184198149.jpg"></P>';
      // a 为富文本的字符串内容，为了测试，只写了img标签
      var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
      var s = a.match(b); // 取到所有img标签 放到数组 s里面
      if (s) {
        for (var i = 0; i < s.length; i++) {
          var srcImg = s[i].replace(b, "$1"); //取src面的内容
          if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
            //若src前4位置或者前5位是http、https则不做任何修改
            console.log("不做任何修改");
          } else {
            //修改富文本字符串内容 img标签src 相对路径改为绝对路径
            a = a.replace(
              new RegExp(srcImg, "g"),
              "https://b.einstein.top/" + srcImg
            );
          }
        }
      }

      // console.log(a);//打印查看是否修改成功。
      return a;
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  background: #eee;
  .main {
    width: 80%;
    background: #fff;
    margin: auto;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding: 0.2rem 1.17rem;
    overflow: hidden;
    h3 {
      font-size: 0.26rem;
      font-weight: bold;
      margin-top: 0.3rem;
    }
    p {
      font-size: 0.12rem;
      margin-top: 0.3rem;
    }
    .cont {
      margin-top: 0.5rem;
    }
  }
}
</style>